<template>
  <page-section >
    <v-row >
      <v-col cols="12" md="6">
        <v-sheet
            :color="wsLIGHTCARD" class="wsRoundedHalf pa-12"
            :class="[
                  {'pa-3' : SM} ,
                  {'pa-12' : !SM}
              ]"
        >
          <v-img  src="@/assets/illustrations/updates.png" />
          <h3 :style="`color : ${wsDARKER};`"
              class="mt-3"
              :class="[ {'text-center' : SM} ]"
          >
            {{ $t('homepage.sections.socials') }}
          </h3>
          <home-socials class="mt-7" large  :color="wsATTENTION" />
        </v-sheet>
      </v-col>
      <v-col cols="12" md="6">
        <v-sheet :color="wsATTENTION" class="wsRoundedHalf" dark
                 :class="[
                    {'pa-3'  :  SM } ,
                    {'pa-12' : !SM }
              ]"
        >
          <v-img  src="@/assets/illustrations/consultation.png" />
          <h3 class="mt-3"
              :class="[ {'text-center' : SM} ]"
          >
            {{ $t('homepage.sections.consultation.title') }}
          </h3>
          <ws-button
              block
              @click="$store.state.homepage.displayContactForm = true"
              color="white" class="mt-3" outlined label="homepage.sections.consultation.order" />
        </v-sheet>
      </v-col>
    </v-row>
  </page-section>
</template>

<script>
import homeSocials from "@/components/pages/homepage_westudy/UI/homeSocials";
export default {
  name: "homeSectionCases",
  components : {
    homeSocials
  },
}
</script>

<style scoped>

</style>